<template>
    <div class="shop_main noMenu" style="background: #FFF" v-loading="isRequestLoading">
        <menuCar type="1" />

        <div class="shop_car_list">
            <div class="table_header">
                <div class="item" style="width: 108px">
<!--                    <el-checkbox v-model="isChange" @change="toChangeAll">全选</el-checkbox>-->
                </div>

                <div class="item" style="flex: 1">
                    <span class="title">商品</span>
                </div>

                <div class="item" style="width: 160px;">
                </div>

                <div class="item" style="width: 160px; text-align: center">
                    <span class="title">数量</span>
                </div>

                <div class="item" style="width: 120px; text-align: center">
                    <span class="title">单价</span>
                </div>

                <div class="item" style="width: 140px; text-align: center">
                    <span class="title">订单金额</span>
                </div>

                <div class="item" style="width: 98px; text-align: center">
                    <span class="title">操作</span>
                </div>
            </div>

            <div class="table_list" v-for="(item, index) in listData" :key="index">
                <div class="info">
                    <el-checkbox v-model="item.isChange" @change="toChangeStore(index, $event)">{{item.store.storeName}}</el-checkbox>
                </div>
                <div class="list" v-for="(good, i) in item.shoppingCartList" :key="i">
                    <el-checkbox v-model="good.isChange" @change="toChangeGood(index, $event)"></el-checkbox>

                    <div class="item" style="flex: 1">
                        <el-image :src="imageURL + good.goodsImageUrl" fit="contain" @click="$router.push({path: '/shop/detail', query: {id: good.goodsId}})"></el-image>
                        <div class="name">
                            <span class="text" @click="$router.push({path: '/shop/detail', query: {id: good.goodsId}})">{{good.goodsName}}</span>
                        </div>
                    </div>

                    <div class="item" style="width: 160px; display: flex; flex-direction: column; justify-content: center; align-items: center">
                        <span class="text" v-for="(sku, j) in good.sku" :key="j">{{sku.skuKey}}：{{sku.skuValue}}</span>
                    </div>

                    <div class="item" style="width: 160px; text-align: center">
                        <div class="number">
                            <inputNumber size="mini" :precision="2" v-model="good.num" :min="0.01" @change="(val) => goodsNumChange(index, i, val)" />
                        </div>
                    </div>

                    <div class="item" style="width: 120px; text-align: center">
                        <span class="text">¥ {{good.price}}</span>
                    </div>

                    <div class="item" style="width: 140px; text-align: center">
                        <span class="text on">¥ {{toFixed(NP.times(good.num, good.price), 2)}}</span>
                    </div>

                    <div class="item" style="width: 98px; text-align: center">
                        <el-button type="text" @click="delData(good.id, index, i)">删除</el-button>
                    </div>
                </div>
            </div>

            <noDataView v-if="listData.length === 0" />
        </div>

        <div class="shop_car_footer" v-if="listData.length > 0">
            <div class="content">
                <span class="number">商品总数：{{num}} 件</span>

                <div class="info">
                    <span class="text">商品总价： <span>¥ {{price}}</span></span>
                    <el-button type="primary" @click="submitForm">确认订单</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import NP from 'number-precision';
  import {getCart, putCart, delCart} from '../../../api/shop/car';
  import {imageURL} from "../../../utils/config";
  import {toFixed} from "../../../utils/method";
  const menuCar = () => import('./menu');
  const noDataView = () => import('../../../components/noDataView');
  const inputNumber = () => import('../../../components/inputNumber');
  export default {
    name: "shopHelp",
    data () {
      return {
        listData: [],
        isRequestLoading: false,
        imageURL: imageURL,
        isChange: false,
        NP: NP,
        price: 0,
        num: 0,
        toFixed: toFixed
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getList()
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getCart(1).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            let data = res.data
            data.forEach((item) => {
              item.isChange = false
              item.shoppingCartList.forEach((good) => {
                good.isChange = false
                good.sku = JSON.parse(good.sku)
              })
            })
            this.listData = data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      toChangeAll (e) {
        this.listData.forEach(item => {
          item.isChange = e
          item.shoppingCartList.forEach(good => {
            good.isChange = e
          })
        })
        this.toQueryPrice()
      },
      toChangeStore (index, e) {
        this.listData[index].shoppingCartList.forEach(good => {
          good.isChange = e
        })
        // this.toQueryChangeAll()

        if (e) {
          this.listData.forEach((store, i) => {
            if (index !== i) {
              store.isChange = false;

              this.listData[i].shoppingCartList.forEach(good => {
                good.isChange = false
              })
            }
          })
        }

        this.toQueryPrice()
      },
      toChangeGood (index, e) {
        let num = 0
        this.listData[index].shoppingCartList.forEach(good => {
          if (good.isChange) {
            num++
          }
        })
        if (num === this.listData[index].shoppingCartList.length) {
          this.listData[index].isChange = true
        } else {
          this.listData[index].isChange = false
        }
        // this.toQueryChangeAll()

        if (e) {
          this.listData.forEach((store, i) => {
            if (index !== i) {
              store.isChange = false;

              this.listData[i].shoppingCartList.forEach(good => {
                good.isChange = false
              })
            }
          })
        }

        this.toQueryPrice()
      },
      toQueryChangeAll () {
        let num = 0
        this.listData.forEach(item => {
          if (item.isChange) {
            num++
          }
        })
        if (num === this.listData.length) {
          this.isChange = true
        } else {
          this.isChange = false
        }
        this.toQueryPrice()
      },
      toQueryPrice () {
        let price = 0
        let num = 0
        this.listData.forEach(item => {
          item.shoppingCartList.forEach(good => {
            if (good.isChange) {
              price = NP.plus(price, NP.times(good.price, good.num))
              num = NP.plus(num, good.num)
            }
          })
        })
        this.price = toFixed(price, 2)
        this.num = num
      },
      goodsNumChange (index, i, val) {
        this.toQueryPrice()
        this.putCart(this.listData[index].shoppingCartList[i].id, val)
      },
      putCart (id, num) {
        putCart(id, {
          data: {
            num: num,
            channel: 1
          }
        })
      },
      delData (id, index, i) {
        this.$confirm('是否确认删除该商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delCart(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '商品删除成功',
                type: 'success',
                showClose: true,
              });
              this.listData[index].shoppingCartList.splice(i, 1)
              this.toQueryPrice()
              // this.toQueryChangeAll()
              this.toChangeGood(index)
            }
          })
        }).catch(() => {});
      },
      submitForm () {
        if (this.num === 0) {
          this.$message({
            message: '请选择商品',
            type: 'warning',
            showClose: true,
          });
          return false
        }

        let data = this.listData
        let submitData = []
        data.forEach((item) => {
          submitData.push({
            shoppingCartList: item.shoppingCartList.filter(good => good.isChange),
            store: item.store
          })
        })
        sessionStorage.setItem('submitOrderData', JSON.stringify(submitData.filter(item => item.shoppingCartList.length > 0)))
        this.$router.push({path:'/shop/car/confirm', query: {type: '1'}})
      }
    },
    components: {
      menuCar, noDataView, inputNumber
    }
  }
</script>
